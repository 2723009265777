import { Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { useGetTypologiesQuery } from "../../services/AppServices";
import { FilterTypology } from "../../utils/inputList/SearchFilter";
import InputFilterTable from "./InputFilterTable";
import XCircleIcon from "@heroicons/react/solid/XCircleIcon";

export default function DropDownTipologia({ header, methodParentFilter }: any) {
  const trigger: any = useRef(null);
  const dropdown: any = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [select, setSelectItem] = useState();
  const [listTypology, setlistTypology] = useState<any[]>([]);

  const { data, isSuccess } = useGetTypologiesQuery("");

  const [listSearch, setListSearch] = useState<any[]>([]);
  const [listSelected] = useState<any[]>([]);

  useEffect(() => {
    if (isSuccess) {
      setlistTypology(data.data);
    }
    if (!dropdownOpen) {
      setListSearch([]);
    }
  }, [isSuccess, data, listTypology, dropdownOpen, listSelected]);

  const handleFilter = (event: any) => {
    let list = listTypology.filter((el: any) =>
      el.attributes.name.toUpperCase().includes(event.Typology.toUpperCase())
    );
    setListSearch(list);
  };

  const selectItem = (item: any) => {
    setSelectItem(item);
    methodParentFilter({ typology: item });
  };

  const clearFilters = () => {
    setSelectItem(undefined);
    methodParentFilter({ typology: "" });
  };

  return (
    <div
      className="inline-block relative group cursor-pointer"
      aria-label="info user"
    >
      <div className="flex flex-row items-center">
        <div
          className="inline-flex items-center justify-center border-[1px] rounded-lg hover:bg-gray-200 px-4 py-2 "
          ref={trigger}
          aria-haspopup="true"
          aria-expanded={dropdownOpen}
          onClick={() => setDropdownOpen(!dropdownOpen)}
        >
          <div className="flex flex-row justify-center items-center">
            <div>{header}</div>

            {/* {select && <div className='text-[9px] text-[#8CA0B3] w-full justify-start flex flex-row'>{truncateNameselect}</div>   } */}
          </div>
        </div>
        <div>
          {select && (
            <XCircleIcon
              className="w-4 h-4 ml-2 text-[#FF0000]"
              onClick={() => clearFilters()}
            />
          )}
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        show={dropdownOpen}
      >
        <ul
          ref={dropdown}
          className="group-hover:block hidden absolute w-56 z-10 origin-top-right bg-white border border-gray-200 rounded-md shadow-lg outline-none py-4"
        >
          <div className=" w-full mb-4 justify-center">
            <InputFilterTable
              title={"Typology"}
              column={"Typology"}
              methodParentFilter={handleFilter}
              FilterType={FilterTypology}
            />
          </div>
          {listSearch.length > 0
            ? listSearch.map((item: any) => (
                <li
                  key={`item-dropdown-${item.attributes.name}`}
                  aria-label={item.attributes.name}
                  className={`${
                    select === item.attributes.name
                      ? "text-[#09acf8]"
                      : "text-[#8CA0B3]"
                  } px-4 py-2  text-[13px] hover:bg-slate-500/10`}
                  onClick={() => selectItem(item.attributes.name)}
                >
                  <div>{item.attributes.name}</div>
                </li>
              ))
            : listTypology.map((item: any) => (
                <li
                  key={`item-dropdown-${item.attributes.name}`}
                  aria-label={item.attributes.name}
                  className={`${
                    select === item.attributes.name
                      ? "text-[#09acf8]"
                      : "text-[#8CA0B3]"
                  } px-4 py-2  text-[13px] hover:bg-slate-500/10`}
                  onClick={() => selectItem(item.attributes.name)}
                >
                  <div>{item.attributes.name}</div>
                </li>
              ))}
        </ul>
      </Transition>
    </div>
  );
}
