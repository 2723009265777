import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { Fragment, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ThemeClass } from '../../store/feature/ThemeSlice';


export interface IDialog {
    contentDialog: any,
    infoRow?: any ,
    basicInfoDialog: any,
    methodParent: any,
    textSize?: string | undefined,
    isBtnDisabled: boolean,
}

export default function DialogComponent({content, dialogInfo, disabledBtn} : {content?: any, dialogInfo?: any, disabledBtn?: boolean}) {
    
    const refDialog = useRef(null);
    const { theme } = useSelector(ThemeClass);
    const { t } = useTranslation();

    return (
            <Transition appear show={dialogInfo?.isOpen ? true : false} as={Fragment}>
                <Dialog
                    as="div"
                    className={`fixed inset-0 z-50 ${theme}`}
                    onClose={() => null}
                    initialFocus={refDialog}
                >
                    <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="absolute inset-0 bg-gray-900 bg-opacity-40 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-200 scale-100"
                            leave="ease-in duration-100"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block relative space-y-8 w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">

                                <div className='flex justify-between'>
                                    <Dialog.Title
                                        as="h3"
                                        className="text-xl leading-6 text-secondary-text"
                                    >
                                       {dialogInfo?.heading}
                                    </Dialog.Title>
                                    <XIcon ref={refDialog} className='w-5- h-5 text-secondary-text  cursor-pointer' onClick={dialogInfo?.closeButtonAction} />
                                </div>

                                {/* Content Dialog */}
                                <div className='text-base text-secondary-text'>
                                  {content}  
                                </div>
                                

                                {!disabledBtn && <div className="flex items-center justify-around">
                                    <button ref={refDialog} type='button' onClick={dialogInfo?.closeButtonAction} className="text-secondary-text h-10 w-32" >
                                        {t(dialogInfo?.closeButtonText)}
                                    </button>
                                    <button ref={refDialog} type='button' onClick={dialogInfo?.confirmButtonAction} className="bg-user-logo text-white w-36 py-2 px-4 rounded-lg text-base font-bold">
                                        {t(dialogInfo?.confirmButtonText)}
                                    </button>
                                </div>}

                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
    )
}