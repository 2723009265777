import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import storage from "redux-persist/lib/storage";

// Create BaseQuery
const baseQuery = fetchBaseQuery({
  // baseUrl: process.env.REACT_APP_API_END_POINT_TEST,
  baseUrl: 'https://apps2portfolio-api.mobilesoftapps.it/',
  //baseUrl: "http://localhost:1337",
  prepareHeaders: (headers, { endpoint }) => {
    let token = localStorage.getItem("token");
    endpoint !== "login" && headers.set("Authorization", `Bearer ${token}`);
    return headers;
  },
});
// Create Ccontroll state calls
const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    storage.removeItem("persist:root");
  }
  return result;
};
// Export Interceptor
export const emptySplitApi: any = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  tagTypes: [],
  keepUnusedDataFor: 0,
});
