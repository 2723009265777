import { BrowserRouter as Router } from "react-router-dom";
import { Suspense } from "react";
import RoutesList from "./routes/Routes";

function App() {
  return (
    <Router>
    <Suspense fallback={<p>LOADING... INSERT YOUR LOADER FOR CALL LAZY</p>}>
      <RoutesList />
    </Suspense>
  </Router>
  );
}

export default App;
