import { Trans } from "react-i18next";
import ErrorMessageInput from "./ErrorMessageInput";

export default function DefaultInput({input, form, type} : any) {

    const { register, formState: { errors } } = form;

    return (
        <div className={`relative h-16 ${input.size_input} ${errors[input.name] ? 'mb-10' : ''} `}>
            <input
                {...register(input.name, {...input.validators})}
                className={`${type === 'login' ? 'pl-5' : ''} bg-transparent peer h-10 mt-6 w-full  text-skin-textInput  focus:outline-none focus:border-skin-inputFocus text-md`}
                autoComplete={'false'}
                id={input.name}
                type={input?.type}
                placeholder={input.placeholder}
                disabled={type === 'view' || input.disabled ? true : false}
            />
            <label htmlFor={input?.name} className={`${type === 'login' ? 'pl-5' : ''} pointer-events-none select-none absolute text-sm left-0 top-2 text-skin-textInput transition-all peer-placeholder-shown:text-skin-textInput peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-8 peer-focus:top-2 peer-focus:text-skin-focus peer-focus:text-sm`}>
                {<Trans i18nKey={input.label} />}
            </label>
            <ErrorMessageInput errors={errors} name={input.name} />
        </div>
    )
}