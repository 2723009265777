export const FormLoginInputs = [
  {
    label: "Email",
    name: "identifier",
    placeholder: "Email",
    type: "text",
    validators: {
      required: "fieldRequired",
      // pattern: {
      //     value: PatternInput.email,
      //     message: 'wrongFormatInput'

      // }
    },

    type_input: "email",
    size_input: "w-full bg-skin-bgInput",
  },
  {
    label: "Password",
    name: "password",
    placeholder: "Password",
    type: "password",
    validators: { required: "fieldRequired" },
    type_input: "password",
    size_input: "w-full bg-skin-bgInput",
  },
];
