// import { QueryStringHelper } from "../../helpers/QueryStringHelper";
import { emptySplitApi } from "./InjectionEndpoint";
export const appApi = emptySplitApi.injectEndpoints({
  endpoints: (build: any) => ({
    login: build.mutation({
      query: (user: any) => ({
        url: "api/auth/local",
        method: "POST",
        body: { ...user },
      }),
      invalidatesTags: [],
    }),

    getMe: build.query({
      query: () => `api/users/me`,
      providesTags: [],
    }),

    getBackend: build.query({
      query: (params: any) => `api/backends=${params?.name}`,
      providesTags: [],
    }),

    getFrontend: build.query({
      query: () => `api/frontends`,
      providesTags: [],
    }),

    getClients: build.query({
      query: (params: any) => `api/customers=${params?.name}`,
      providesTags: [],
    }),

    getMarkets: build.query({
      query: () => `api/markets`,
      providesTags: [],
    }),

    getTypologies: build.query({
      query: () => `api/typologies`,
      providesTags: [],
    }),

    getDataSheets: build.query({
      query: () =>
        `api/data-sheets?filters=[project][$containsi]=pay&populate=customer`,
      providesTags: [],
    }),

    getDataSheetsArchiveById: build.query({
      query: (id: any) =>
        `api/data-sheets/${id}?populate=customer,photos,frontend.technology,backend.technology,market.market,contact,typology.typology,design.technology,test.technology`,
      providesTags: [],
    }),

    getDataSheetsArchiveSearch: build.query({
      query: (data: any) =>
        `api/data-sheets?${
          data && data.code ? `&filters[code][$containsi]=${data.code}` : ``
        }${
          data && data.project
            ? `&filters[project][$containsi]=${data.project}`
            : ``
        }${
          data && data.market
            ? `&filters[market][market][name][$containsi]=${data.market}`
            : ``
        }${
          data && data.typology
            ? `&filters[typology][typology][name][$containsi]=${data.typology}`
            : ``
        }${
          data && data.customer
            ? `&filters[customer][name][$containsi]=${data.customer}`
            : ``
        }${
          data && data.startAt
            ? `&filters[startAt][$containsi]=${data.startAt}`
            : ``
        }${
          data && data.finalCustomer
            ? `&filters[finalCustomer][$containsi]=${data.finalCustomer}`
            : ``
        }${
          data && data.frontend
            ? `&filters[frontend][technology][name][$containsi]=${data.frontend}`
            : ``
        }${
          data && data.backend
            ? `&filters[backend][technology][name][$containsi]=${data.backend}`
            : ``
        }${
          data && data.test
            ? `&filters[test][technology][name][$containsi]=${data.test}`
            : ``
        }${
          data && data.design
            ? `&filters[design][technology][name][$containsi]=${data.design}`
            : ``
        }&populate=customer,photos,frontend.technology,backend.technology,market.market,contact,typology.typology,design.technology,test.technology&pagination[page]=${
          data && data.pageIndex ? data.pageIndex : 0
        }&pagination[pageSize]=${
          data && data.pageSize ? data.pageSize : 10
        }&sort[0]=${
          data && data.sort ? data.columnSort + ":" + data.sort : "startAt:desc"
        }`,
      providesTags: [],
    }),

    getFilters: build.query({
      query: (params: any) => `api/${params.column}`,
      providesTags: [],
    }),

    getExportCsv: build.mutation({
      query: () => ({
        url: "api/exportCSV",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useGetMeQuery,
  useGetBackendQuery,
  useGetFrontendQuery,
  useGetClientsQuery,
  useGetMarketsQuery,
  useGetTypologiesQuery,
  useGetDataSheetsQuery,
  useGetDataSheetsArchiveByIdQuery,
  useGetDataSheetsArchiveSearchQuery,
  useGetFiltersQuery,
  useGetExportCsvMutation,
} = appApi;
