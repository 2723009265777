import InfoDetail from "./InfoDetail";

export default function InfoContacts(props: { contacts: any[] }) {

  return (
    <>
      {props.contacts.map((el: any, int: number) => (
        <div key={el.id} className="w-full h-auto pb-2">
          <div>
            <div className="">
               {el.name ? el.name : '---'}
            </div>

            <hr className="my-2 scale-x-150" />
            <div className="grid grid-cols-2 gap-5 p-4" key={el.name}>
              <InfoDetail title="Nominativo" value={el.name}></InfoDetail>
              <InfoDetail title="Telefono" value={el.phone}></InfoDetail>
              <InfoDetail title="Email" value={el.email}></InfoDetail>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
