import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SmallLoader from "../../components/main/SmallLoader";
import TableComponent from "../../components/shared/TableComponent";
import {
  HeadersAxiosPag,
  initialStatePag,
} from "../../components/helpers/HeadersAxiosPag";
import { HeaderTab } from "../../utils/header/HeaderTab";
import {
  useGetDataSheetsArchiveSearchQuery,
  useGetExportCsvMutation,
} from "../../services/AppServices";
import { ReactComponent as ArrowDownTraySvg } from "../../assets/mainIcons/arrow_down_try.svg";
import { saveAs } from "file-saver";
import { ArchiveModel } from "../../models/archive-model";

export default function Archive() {
  const dispatch = useDispatch();

  const [getExportCsv] = useGetExportCsvMutation();

  const [listConsumers, setListConsumers] = useState<ArchiveModel[]>([]);
  const [metaInfo, setMetaInfo] = useState({});
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [infoQueryPage, setInfoQueryPage] = useState(initialStatePag);
  const [sort, setSortInfo] = useState();
  const [orderSort, setOrderSort] = useState(null);
  const [currentOrderId, setCurrentOrderId] = useState();
  const [valuesFil, setValuesFil] = useState();

  const handlePagination = (data: any) => {
    setPageIndex(data?.pageIndex);
    setInfoQueryPage(data);
    setPageSize(data.pageSize);
    setValuesFil(data);
  };

  const handleDownloadCsv = async () => {
    const result = await getExportCsv("");
    let file = new Blob([result?.error.data]);
    let date = new Date();
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    saveAs(file, `Projects-${day}-${month}-${year}.csv`);
  };

  // const {data, error, isLoading, isSuccess} = useGetDataSheetsQuery(null);
  const { data, isSuccess, isFetching } =
    useGetDataSheetsArchiveSearchQuery(valuesFil);
  useEffect(() => {
    if (isSuccess) {
      console.log("FACCIO LA CHIAMATA");
      const arrayDate = JSON.parse(JSON.stringify(data));

      arrayDate.data.forEach((element: any) => {
        element.attributes.startAt = new Date(
          element.attributes.startAt
        ).getFullYear();
      });

      setListConsumers(arrayDate.data);
      setMetaInfo(data.meta.pagination);
    }
  }, [isSuccess, data, setListConsumers, setMetaInfo]);

  const handleSort = (directionSort: any, column: any) => {
    setOrderSort(directionSort);
    let sortDirection =
      directionSort === "desc"
        ? `${column.id}`
        : directionSort === "asc"
        ? column.id
        : "-createdAt";
    setSortInfo(sortDirection);
    setCurrentOrderId(directionSort ? column.id : null);
    let data: any = {
      columnSort: column.id === "customer" ? column.id + ".name" : column.id,
      sort: directionSort,
    };
    setValuesFil(data);
  };

  const returnFilter = (filter: any) => {
    setValuesFil((prevState: any) => {
      return { ...prevState, ...filter };
    });
  };

  useEffect(() => {
    let params: any = {};
    params = HeadersAxiosPag(infoQueryPage);

    if (sort) {
      params["x-pagination-sort"] = sort;
    }
  }, [dispatch, infoQueryPage, sort, valuesFil]);

  // CLOSE PANEL WHEN DESTROY COMPONENT
  // useEffect(() => {
  //     return () => {
  //         dispatch(setOpenBoxUserView({ type: '', isOpenView: false, infoBox: null, tabReference: 'consumer' }))
  //     };
  // }, [dispatch])

  return (
    <>
      {/* <TitleActionBarTopPage title={'consumer'} methodParentFilter={handleFilter} /> */}
      <div className="fixed top-[18px] right-[320px] z-[9999999]">
        <button
          onClick={() => {
            handleDownloadCsv();
          }}
          className="flex justify-items-end px-2 py-2 border-solid border-2  rounded-lg bg-white"
        >
          <ArrowDownTraySvg aria-label="Scarica CSV" className="w-5 h-5 mr-2" />
          Scarica CSV
        </button>
      </div>
      <div className="flex flex-wrap 3xl:w-4/5 mx-auto relative max-w-screen-3xl text-base text-secondary-text font-semibold">
        {/* Left Col */}
        <div className="mb-10  w-full">
          <div className="relative">
            {isFetching && (
              <>
                <SmallLoader
                  isAbsolute={true}
                  top={false}
                  hideBg={true}
                ></SmallLoader>
              </>
            )}
            <TableComponent
              tabHeader={HeaderTab}
              dataTable={listConsumers}
              meta={metaInfo}
              pageIndex={pageIndex}
              pageSizeParent={pageSize}
              parentMethod={handlePagination}
              returnFilter={returnFilter}
              sortFun={handleSort}
              sortDirectionParent={orderSort}
              currentSortCol={currentOrderId}
              messageResult={
                listConsumers.length === 0
                  ? valuesFil
                    ? "NO_FILTER_ITEMS_FOUND"
                    : "NO_ITEMS_FOUND"
                  : null
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
