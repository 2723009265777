import DropDownUser from "../shared/DropDownUser";

export default function Toolbar() {

    return(
        <header className="sticky top-0 border-b z-30 h-20 py-8 bg-[#F7F7F7]">
            <div className="flex items-center justify-between pl-4 sm:pl-6 lg:pl-8 h-full">
                <p className="text-2xl text-[#8CA0B3]" aria-label="Apps2Portfolio titolo">Apps2Portfolio</p>
                <DropDownUser />
            </div>
        </header>
    )
}