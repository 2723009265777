import logoApp2 from '../../assets/mainIcons/mainLogo.png';

export interface ISidebar {
    setIsOpen: any;
    isSidebarOpen: boolean
}

export default function Sidebar() {

    return (
        // <div className="group flex flex-col items-center shrink-0 overflow-hidden w-16 z-40 hover:w-60 left-0 absolute top-0 h-screen bg-secondary-bg border-r transition-all duration-800 ease-in-out">
        //     <button className="flex items-center justify-center w-16 group-hover:w-full group-hover:justify-start group-hover:pl-5 h-20">
        //     <img src={logoApp2} alt='logo-app-2-push' style={{width: 36}} />
        //     </button>

        //     <div className="my-2 xl:space-y-7 space-y-3 w-full">
        //         {}
        //     </div>
        // </div>
                <div className="group flex flex-col items-center shrink-0 overflow-hidden w-16   left-0 absolute top-0 h-screen bg-secondary-bg border-r transition-all duration-800 ease-in-out">
            <button className="flex items-center justify-center w-16  h-20">
            <img src={logoApp2} alt='logo-app-2-push' style={{width: 36}} />
            </button>

            <div className="my-2 xl:space-y-7 space-y-3 w-full">
                {}
            </div>
        </div>

    )
}