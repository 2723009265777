export interface IParamsPage {
    'x-pagination-limit': number,
    'x-pagination-skip': number | null,
    'x-pagination-sort': string
}

export function HeadersAxiosPag(infoQueryPage: any) {

    const {pageSize, pageIndex}: any = infoQueryPage;

    const headers: IParamsPage = {
        'x-pagination-limit': pageSize ? pageSize : 5,
        'x-pagination-skip': pageSize ? pageSize * pageIndex : null,
        'x-pagination-sort': '-createdAt'
    }

    return headers;
}

export const initialStatePag = {pageIndex: 0, pageSize: 5}
