
import { TrashIcon, UploadIcon } from "@heroicons/react/outline";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Trans } from "react-i18next";
import { SelectTypeValidatorDropZone } from "../../components/helpers/SelectDropzoneValidationHelper";

export default function Dropzone({ onChange, form, label, name, type, logoUrl, validator }: any) {

    const { setValue, setError, clearErrors } = form;
    const [fileUpload, setFiles] = useState([]);
    const [errorType, setErrorType] = useState(false);
    const [updateModeLogo, setUpdateModLogo] = useState(false);
    let isDisabled: boolean = type === 'view' ? true : false;
    let typeValidator = SelectTypeValidatorDropZone(validator);

    useEffect(() => {
        logoUrl ? setUpdateModLogo(true) : setUpdateModLogo(false);
    }, [logoUrl, type])

    const onDrop = useCallback((acceptedFiles: any, fileRejections: any) => {
        if (fileRejections && fileRejections.length > 0) {
            setValue(name, fileRejections[0].file)
            setErrorType(true);
            setError(name, {
                type: 'manual',
                message: fileRejections && fileRejections[0].errors[0].message,
            })
        } else {
            let currentFile = acceptedFiles.map((file: any) => Object.assign(file, {preview: URL.createObjectURL(file)}))
            setFiles(currentFile);
            setValue(name, currentFile[0])
            setErrorType(false);
            clearErrors(name);
        }
        // eslint-disable-next-line
    }, [setError, name, clearErrors, setValue]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, disabled: isDisabled, ...typeValidator });

    const preview = fileUpload.map((file: any) => (
        <div key={file.name} className='h-auto border rounded-md py-6 flex flex-col justify-center items-center bg-gray-100'>
            {file.type.includes('image') && file.type !== 'image/pdf'
                ? <img src={file.preview} alt='preview-logo' className='mb-4 h-14 w-auto px-2' />
                : <p className='mb-4 h-14 w-auto px-2'>{file.name}</p>
            }
            {type !== 'view' && <TrashIcon className='cursor-pointer w-6 h-6 text-skin-focus' onClick={() => handleDelete()} />}
        </div>
    ));

    const handleDelete = () => {
        setFiles([]);
        setValue(name, null, {shouldValidate: true});
        setUpdateModLogo(false);
        setErrorType(false);
        clearErrors(name)
    }

    return (
        <>
            {fileUpload.length < 1 && !updateModeLogo && !errorType
                ?
                <div {...getRootProps({ className: 'dropzone-container' })} className={`${isDragActive ? 'border-dashed border-2' : 'border'} rounded-md h-36 py-6 flex flex-col justify-center items-center cursor-pointer`}>
                    <input {...getInputProps({ onChange })} />
                    {
                        isDragActive ? <div className="w-full h-full flex justify-center items-center">
                            <span className="block text-grey font-regular text-base">Trascina qui il tuo logo</span>
                        </div>
                            : <> <UploadIcon className="w-8 h-8 text-skin-focus" />
                                <p className='font-regular text-base mt-5 text-skin-textName'>{<Trans i18nKey={label} />}</p>
                            </>
                    }
                </div>
                : <>
                    {!updateModeLogo && preview}
                    {(updateModeLogo || errorType) && <div className='h-auto border rounded-md py-6 flex flex-col justify-center items-center bg-gray-100'>
                        {!errorType && <img src={logoUrl} alt='preview-logo' className='mb-4 max-h-24 w-auto px-2' />}
                        {type !== 'view' && <TrashIcon className='cursor-pointer w-6 h-6 text-skin-focus' onClick={() => handleDelete()} />}
                    </div>}
                </>
            }
        </>
    );
}