import { useState } from "react";
import { useSelector } from "react-redux";
import contact from "../../assets/mainIcons/contact-book.svg";
import InfoContacts from "../shared/InfoContacts";
import { useGetDataSheetsArchiveByIdQuery } from "../../services/AppServices";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  isOpenBoxViewUser,
  IViewUserBoxSlice,
} from "../../store/feature/SetOpenBoxUserSlice";
import DialogContactComponent from "../main/DialogContactComponent";

export default function DialogContacts() {
  const { infoBox }: IViewUserBoxSlice = useSelector(isOpenBoxViewUser);

  const { data: dataById } = useGetDataSheetsArchiveByIdQuery(
    infoBox?.id ? infoBox.id : skipToken
  );

  const [isOpen, setOpen] = useState(false);

  const contentDialog = () => (
    <div className="flex flex-col text-center">
      <InfoContacts
        contacts={dataById?.data?.attributes?.contact}
      ></InfoContacts>
    </div>
  );

  const DialogInfo = {
    icon: <img src={contact} alt="contact" />,
    heading: "Contatti",
    content: contentDialog(),
    closeButtonText: "Chiudi",
    closeButtonAction: () => setOpen(false),
    isOpen: isOpen,
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setOpen(true)}
        className="p-1 border-2 rounded-md  select-none w-auto  flex items-center h-8  text-base text-secondary-text hover:bg-skin-bgInput"
      >
        {DialogInfo?.icon}
      </button>
      <DialogContactComponent
        content={DialogInfo?.content}
        dialogInfo={DialogInfo}
      />
    </>
  );
}
