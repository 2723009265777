import { Transition } from "@headlessui/react";
import { Fragment, useRef, useState } from "react";
import { SelectDialogType } from "../../helpers/SelectDialogType";
import { useGetMeQuery } from "../../services/AppServices";

export default function DropDownUser() {
  const infoRow = null;
  const trigger: any = useRef(null);
  const dropdown: any = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { data: user } = useGetMeQuery(null, {});

  return (
    <div
      className="inline-block relative group cursor-pointer"
      aria-label="info user"
    >
      <div
        className="inline-flex items-center justify-center h-20 hover:bg-gray-200 ml-14 pr-14"
        ref={trigger}
        aria-haspopup="true"
        aria-expanded={dropdownOpen}
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        <div className="flex flex-row w-full justify-between items-center ml-4">
          <div className="h-[40px] w-[40px] rounded-full bg-user-logo flex flex-row justify-center items-center mx-2 mr-5 text-white">
            {user?.firstname[0].toUpperCase()}
            {user?.lastname[0].toUpperCase()}
          </div>
          <div className="text-primary-text  text-lg text-userDialogText">
            {user?.firstname} {user?.lastname}
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        show={dropdownOpen}
      >
        <ul
          ref={dropdown}
          className="group-hover:block hidden absolute w-56 z-10 origin-top-right bg-white border border-gray-200 rounded-md shadow-lg outline-none"
        >
          {MenuUserItems().map((item: any) => (
            <li key={`item-dropdown-${item.label}`} aria-label={item?.label}>
              {
                // item.type === 'itemMenu'
                // ? <div onClick={() => handleClick(item)} className='flex items-center justify-between w-full h-14 text-skin-default text-base hover:bg-skin-bgInput'>
                //     <div className={`flex ${item?.icon ? '' : 'pl-4'}`}>
                //     {item?.icon}
                //     {item?.label}
                //     </div>

                //     <div className='px-5'>
                //         {item?.component}
                //     </div>
                // </div>
                //:
                SelectDialogType(item.typeDialog, infoRow)
              }
            </li>
          ))}
        </ul>
      </Transition>
    </div>
  );
}

export const MenuUserItems = () => [
  // {
  //     icon: '',
  //     label: 'switchTheme',
  //     type: 'itemMenu',
  //     //component: <ToggleTheme />
  // },
  {
    label: "Logout",
    type: "dialogOpen",
    typeDialog: "DIALOG_LOGOUT",
    idDialog: "dialogLogoutMenu",
  },
];
