import { Outlet } from "react-router-dom"
import { Slide, ToastContainer } from "react-toastify"
import Sidebar from "../../components/main/Sidebar"
import Toolbar from "../../components/main/Toolbar"
import { injectStyle } from "react-toastify/dist/inject-style";
import Archive from "../archive/Archive";

export default function Dashboard() {

    if (typeof window !== "undefined") {
        injectStyle();
    }

    return (
        <div className='flex h-screen overflow-hidden'>
            <Sidebar />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-[#F7F7F7] ml-16">
                <Toolbar />
                <main>
                    <Archive />
                    <div className="w-full max-w-9xl mx-auto">
                        <Outlet />
                    </div>
                    <ToastContainer autoClose={5000} limit={4} transition={Slide} pauseOnFocusLoss={false} className='fixed sm:bottom-14 bottom-16 right-0 my-8 max-w-lg p-3 rounded-md' />
                </main>
            </div>
        </div>
    )
}