import LogoMobilesoft from "../../assets/mainIcons/apps2portfolio.png";
import LoginForm from "../../components/main/LoginForm";
import mobilesoftLogo from "../../assets/mainIcons/logo mobilesoft.png";

export default function LoginPage() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-base-bg mx-auto font-base">
      <div className="border shadow-md rounded-md sm:p-10 p-8 bg-secondary-bg lg:w-3/4 xl:w-1/3 w-5/6 space-y-10">
        <div className="flex justify-between items-center w-full mb-20">
          <img
            src={LogoMobilesoft}
            alt="logo-app2push"
            aria-label="Logo App2Push"
            style={{ width: 270 }}
          />
          {/* <LanguageBtn /> */}
        </div>

        <LoginForm />

        <div className="flex flex-col justify-center items-center space-y-3 text-skin-default text-sm">
          <img
            src={mobilesoftLogo}
            alt="logo-login-mobilesoft"
            aria-label="Logo Mobilesoft"
            style={{ width: 150 }}
          />
          <p>@ 2023 MobileSoft S.r.l. Apps2Portfolio 0.0.1</p>
        </div>
      </div>
    </div>
  );
}
