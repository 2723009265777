import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { IUser } from "../../utils/models/UsersModels";
// import { IFilters } from "../../utils/models/FiltersModels";
// import { IRecipients } from "../../utils/models/RecipientsModels";
import { RootState } from "../Store";
// import { IConsumers } from "../../utils/models/AppModels";
// import { IMessage } from "../../utils/models/MessagesModels";
// import { ITemplate } from "../../utils/models/TemplateModel";


export interface IViewUserBoxSlice {
    type?: string,
    isOpenView: boolean,
    infoBox?: any | null ,
    tabReference: string | null
}

export const initialStateSLiceOpenBox: IViewUserBoxSlice = {
    type: '',
    isOpenView: false,
    infoBox: null,
    tabReference: null
}

const setOpenBoxUserViewSlice = createSlice({
    name: 'SET_OPEN_BOX_USER_VIEW',
    initialState: initialStateSLiceOpenBox,

    reducers: {
        setOpenBoxUserView: (state, {payload}: PayloadAction<IViewUserBoxSlice>) => {
            return {...payload};
        }
    }
    
})

export const { setOpenBoxUserView } = setOpenBoxUserViewSlice.actions;

//EXPORT SELECTRO FOR CHILD
export const isOpenBoxViewUser = (state: RootState) => state.SetOpenBoxUserSlice;

export default setOpenBoxUserViewSlice.reducer