import { debounce } from "debounce";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ThemeClass } from "../../store/feature/ThemeSlice";

export default function DebounceSearchInput({ input, form, method }: any) {
  const { register } = form;
  const { theme } = useSelector(ThemeClass);
  const { t } = useTranslation();

  return (
    <div
      className={`flex justify-center-between items-center px-1 ${input?.size_input}`}
      key={input.name}
    >
      <div className="relative fill-iconColor text-skin-default">
        <span className="absolute inset-y-0 left-0 flex items-center ">
          <span className="p-1 focus:outline-none focus:shadow-outline"></span>
        </span>
        <input
          {...register(input.name, { ...input.validators })}
          className={`placeholder:text-md w-fit h-10  focus:border-x-transparent focus:border-t-transparent focus:ring-0 text-md  ${
            theme === "dark" ? "border-skin-input" : "border-transparent	"
          } focus-within:border-skin-inputFocus focus:ring-1 outline-none py-1 px-1 border-b-[1px] border-b-black leading-8 transition-colors duration-200 ease-in-out`}
          autoComplete="off"
          placeholder={t(input.placeholder)}
          type={input.type}
          aria-describedby={input.placeholder ? input.placeholder : input.label}
          onChange={debounce((e: any) => {
            method(e.target.value);
          }, 500)}
        />
      </div>
    </div>
  );
}
