import { memo } from "react";

export type TooltipProps = {
    children: React.ReactNode;
    text: string;
  };
  
  const Tooltip: React.FC<TooltipProps> = memo((props) => {
    return (
      <div className="group relative">
        <p className="pointer-events-none absolute z-30  text-[10px] flex flex-row justify-center break-words -bottom-8 left-1/2 -translate-x-1/2 whitespace-nowrap rounded bg-black/50 px-2 py-1 text-white opacity-0 transition before:absolute before:left-1/2 before:top-full before:-translate-x-1/2 before:border-4 before:border-transparent  before:content-[''] group-hover:opacity-100">
          {props.text}
        </p>
  
        {props.children}
      </div>
    );
  });
  
  Tooltip.displayName = "Tooltip";
  
  export default Tooltip;
