import { Dialog, Transition } from "@headlessui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useGetDataSheetsArchiveByIdQuery } from "../../services/AppServices";
import {
  isOpenBoxViewUser,
  IViewUserBoxSlice,
} from "../../store/feature/SetOpenBoxUserSlice";
import CarouselDialogImage from "../shared/CarouselDialogImage";

export default function DialogImage(props: {
  open: boolean;
  url: string;
  onSelect: (select: boolean) => void;
}) {
  const url = props.url;

  const { infoBox }: IViewUserBoxSlice = useSelector(isOpenBoxViewUser);

  const { data: dataById } = useGetDataSheetsArchiveByIdQuery(
    infoBox?.id ? infoBox.id : skipToken
  );

  async function downloadImage() {
    const image = await fetch(url);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);

    const link = document.createElement("a");
    link.href = imageURL;
    link.download = "Apps2Portfolio";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  let [isOpen, setIsOpen] = useState(props.open);

  function closeModal() {
    props.onSelect(false);
    setIsOpen(false);
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full xl:w-3/4  transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <CarouselDialogImage
                    photos={dataById?.data.attributes.photos?.data}
                  />

                  {/* <div className='w-full flex flex-row justify-center items-center mb-8'>
                    <img className=" " alt="" src={props.url} />
                  </div> */}

                  <div className="flex col-span-2 justify-end items-center space-x-14 py-3 -mb-4 px-8 w-full border-t bg-white rounded-lg">
                    <button
                      onClick={downloadImage}
                      className="bg-primary-button text-white w-36 py-2 px-4 rounded-lg text-base font-bold"
                    >
                      Download
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
