import DialogError from "../components/dialogs/DialogError";
import DialogLogout from "../components/dialogs/DialogLogout";


export function SelectDialogType(type: string, infoCurrentRow: any, actionType?: string, isOpen?: boolean, title?: string, parentMethod?: any) {

    const DialogType: any = {
        // DELETE_USER: <DialogDeleteUser infoRow={infoCurrentRow} />,
        // DELETE_CONSUMER: <DialogDeleteConsumer infoRow={infoCurrentRow} />,
        // DELETE_FILTER: <DialogDeleteFilter infoRow={infoCurrentRow} />,
        // DELETE_RECIPIENT: <DialogDeleteRecipient infoRow={infoCurrentRow} />,
        DIALOG_LOGOUT: <DialogLogout />,
        // DEACTIVATE_RECIPIENT: <DialogDeactivateRecipient infoRow={infoCurrentRow} />,
        // DEACTIVATE_USER: <DialogDeactivateUser infoRow={infoCurrentRow} />,
        // DEACTIVATE_CONSUMER: <DialogDeactivateConsumer infoRow={infoCurrentRow} />,
        // DEACTIVATE_FILTER: <DialogDeactivateFilter infoRow={infoCurrentRow} />,
        // DELETE_MESSAGE: <DialogDeleteMessage infoRow={infoCurrentRow} />,
        // DELETE_TEMPLATE: <DialogDeleteTemplate infoRow={infoCurrentRow} />,
        // DEACTIVATE_TEMPLATE: <DialogDeactivateTemplate infoRow={infoCurrentRow} />,
        // IMPERSONATE_USER: <DialogImpersonateUser infoRow={infoCurrentRow} actionType={actionType} />,
        DIALOG_ERROR: <DialogError message={infoCurrentRow} isOpen={isOpen} title={title} parentMethod={parentMethod} />
    };
    
    return DialogType[type];
}