import { XIcon } from "@heroicons/react/solid";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import InputsFields from "../helpers/InputFields";
import {
  isOpenBoxViewUser,
  setOpenBoxUserView,
} from "../../store/feature/SetOpenBoxUserSlice";

export default function InputFilterTable({
  methodParentFilter,
  isPanelOpen,
  FilterType,
  column,
}: any) {
  const form = useForm();
  const dispatch = useDispatch();
  const { isOpenView } = useSelector(isOpenBoxViewUser);

  const handleCallMethodParent = (filter: any) => {
    methodParentFilter({ [column]: filter });
  };

  const handleClosePanelInfo = () => {
    dispatch(
      setOpenBoxUserView({
        type: "",
        isOpenView: true,
        infoBox: null,
        tabReference: "recipients",
      })
    );
  };

  return (
    <div className="placeholder:text-md bg-white  h-10 outline-0 text-md ">
      {/* <p className="text-xl text-skin-textInput w-44 capitalize"><Trans i18nKey={title} /></p> */}

      {methodParentFilter && !isPanelOpen && (
        <div className="flex justify-center items-center h-full w-full ">
          <InputsFields
            arrFields={FilterType}
            form={form}
            method={handleCallMethodParent}
          />
          {/* <InputsFields arrFields={Filters} form={form} typeView={'filter'}  /> */}
        </div>
      )}

      {isOpenView && isPanelOpen && (
        <button className="absolute right-20 " onClick={handleClosePanelInfo}>
          <XIcon className="w-8 h-8" />
        </button>
      )}
    </div>
  );
}
