import DropDownMarket from "./DropDownMarket";
import DropDownTipologia from "./DropDownTipologia";
import DropDownFilters from "./DropDownFilters";

export default function DropDownType({ column, methodParentFilter }: any) {
  return (
    <>
      {column.id === "market" && (
        <DropDownMarket
          header={column.Header}
          methodParentFilter={methodParentFilter}
        ></DropDownMarket>
      )}
      {column.id === "tipology" && (
        <DropDownTipologia
          header={column.Header}
          methodParentFilter={methodParentFilter}
        ></DropDownTipologia>
      )}

      {column.id === "frontend" && (
        <DropDownFilters
          header={column.Header}
          methodParentFilter={methodParentFilter}
          column={column.id}
        ></DropDownFilters>
      )}

      {column.id === "backend" && (
        <DropDownFilters
          header={column.Header}
          methodParentFilter={methodParentFilter}
          column={column.id}
        ></DropDownFilters>
      )}

      {column.id === "test" && (
        <DropDownFilters
          header={column.Header}
          methodParentFilter={methodParentFilter}
          column={column.id}
        ></DropDownFilters>
      )}

      {column.id === "design" && (
        <DropDownFilters
          header={column.Header}
          methodParentFilter={methodParentFilter}
          column={column.id}
        ></DropDownFilters>
      )}
    </>
  );
}
