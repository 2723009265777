export default function SmallLoader({
  isAbsolute,
  top,
  isBtnLoader,
  hideBg = false,
}: {
  isAbsolute: boolean;
  top: boolean;
  isBtnLoader?: boolean;
  hideBg: boolean;
}) {
  return (
    <div
      className={`rounded-lg ${!hideBg && "bg-skin-bgBoxCol"} ${
        isAbsolute
          ? top
            ? "absolute -top-6 left-0 bottom-0 right-0 z-20"
            : "absolute top-0 left-0 bottom-0 right-0 z-20"
          : "opacity-90"
      }`}
    >
      <div className=" flex justify-center items-center h-full">
        <div
          className={`animate-spin rounded-full ${
            isBtnLoader ? "h-6 w-6" : "h-20 w-20"
          } border-t-2 border-b-2 border-[#09ACF8]`}
        ></div>
      </div>
    </div>
  );
}
