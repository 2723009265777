import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { emptySplitApi } from "./../services/InjectionEndpoint";
import storage from 'redux-persist/lib/storage';
import SetOpenBoxUserSlice from "./feature/SetOpenBoxUserSlice";
import setRememberMeSlice from "./feature/RememberMeSlice";
import setThemeClass from "./feature/ThemeSlice"


import {
  persistStore,
  persistReducer,
} from 'redux-persist';

const reducers = combineReducers({
  
  SetOpenBoxUserSlice,
  setThemeClass,
  setRememberMeSlice,


  [emptySplitApi.reducerPath]: emptySplitApi.reducer,
});
const persistConfig: any = {
  key: 'root',
  storage,
  whitelist: ['SetOpenBoxUserSlice', 'setThemeClass', 'setRememberMeSlice']
}
const persistedReducer = persistReducer(persistConfig, reducers);
export const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>    getDefaultMiddleware({

      serializableCheck: false
    }).concat(
      emptySplitApi.middleware,
    )
});
export let persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;