import { ChevronLeftIcon } from "@heroicons/react/outline";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetDataSheetsArchiveByIdQuery } from "../../services/AppServices";
import {
  isOpenBoxViewUser,
  IViewUserBoxSlice,
} from "../../store/feature/SetOpenBoxUserSlice";
import GridImages from "../shared/gridImages";
import InfoChipsMarket from "../shared/InfoChipsMarket";
import InfoChipsTechnologyBe from "../shared/InfoChipsTechnologyBe";
import InfoChipsTechnologyFe from "../shared/InfoChipsTechnologyFe";
import InfoChipsTypology from "../shared/InfoChipsTypology";
import InfoDetail from "../shared/InfoDetail";
import InfoDetailTextArea from "../shared/InfoDetailTextArea";
import Sidebar from "./Sidebar";
import SmallLoader from "./SmallLoader";
import Toolbar from "./Toolbar";
import DialogContacts from "../dialogs/dialogContacts";
import InfoChipsTechnologyTest from "../shared/infoChipsTechnologyTest";
import InfoChipsTechnologyUiUx from "../shared/infoChipsTechnologyUiUx";

export default function ArchiveDetail() {
  const { infoBox }: IViewUserBoxSlice = useSelector(isOpenBoxViewUser);

  const { isSuccess: succesById, data: dataById } =
    useGetDataSheetsArchiveByIdQuery(infoBox?.id ? infoBox.id : skipToken);

  const navigate = useNavigate();
  const handleClick = (navigateTo: any) => {
    navigateTo && navigate(navigateTo);
  };

  async function downloadImage(photos: any) {
    photos.forEach(async (el: any) => {
      const image = await fetch(
        "https://apps2portfolio-api.mobilesoftapps.it/" + el.attributes.url
      );
      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);

      const link = document.createElement("a");
      link.href = imageURL;
      link.download = el.attributes.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  return (
    <>
      <div className="flex h-screen overflow-hidden w-full">
        <Sidebar />
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-[#F7F7F7] ml-16">
          <Toolbar />
          <main>
            {!succesById && (
              <SmallLoader
                hideBg={false}
                isAbsolute={true}
                isBtnLoader={false}
                top={false}
              />
            )}
            {succesById && (
              <div className="rounded-lg w-full flex flex-col items-center  min-h-[80px]">
                <div className="flex flex-row flex-wrap justify-around items-center px-16  bg-white border-b-[1px] w-full min-h-[80px]">
                  <button className="ml-[-30px] mr-4 p-1 border-2 rounded-md">
                    <ChevronLeftIcon
                      onClick={() => handleClick("/dashboard")}
                      className="w-5 h-5 text-black"
                    />
                  </button>
                  <InfoDetail
                    title="Data commessa progetto"
                    value={new Date(
                      dataById?.data.attributes.startAt
                    ).getFullYear()}
                  ></InfoDetail>
                  <div className="flex items-center ">
                    <InfoDetail
                      title="Codice progetto"
                      value={dataById?.data.attributes.code}
                    ></InfoDetail>
                  </div>

                  <div className="items-center flex flex-row ">
                    <InfoDetail
                      title="Nome Progetto"
                      value={dataById?.data.attributes.project}
                    ></InfoDetail>
                  </div>
                  <div className="items-center  flex flex-row ">
                    <InfoDetail
                      title="Committente"
                      value={dataById?.data.attributes.finalCustomer}
                    ></InfoDetail>
                  </div>

                  <div className="flex flex-row justify-start items-center">
                    <DialogContacts></DialogContacts>
                    <div className="ml-6">
                      <InfoDetail
                        title="Cliente"
                        value={
                          dataById?.data.attributes.customer.data?.attributes
                            .name
                            ? dataById?.data.attributes.customer.data
                                ?.attributes.name
                            : "---"
                        }
                      ></InfoDetail>
                    </div>
                  </div>
                </div>

                <div className="flex lg:flex-row flex-col bg-white max-w-[90%] w-full shadow-xl shadow-black/10 p-4 ">
                  <div className="flex flex-col justify-start items-start w-1/4 max-w-[450px] p-6 pr-0">
                    <div className="pr-8 ">
                      <div className="mb-6">
                        <InfoChipsMarket
                          title="Mercato"
                          value={dataById?.data.attributes.market}
                        ></InfoChipsMarket>
                      </div>
                      <InfoChipsTypology
                        title="Tipologia"
                        value={dataById?.data.attributes.typology}
                      ></InfoChipsTypology>
                    </div>
                  </div>

                  <div className="inline-grid grid-cols-1 gap-4 w-2/4 py-6 pr-8">
                    <InfoDetailTextArea
                      title="Descrizione progetto"
                      value={dataById?.data?.attributes.description}
                    ></InfoDetailTextArea>
                    <div className="mt-8">
                      <InfoDetailTextArea
                        title="Attività svolta"
                        value={dataById?.data?.attributes.activity}
                      ></InfoDetailTextArea>
                    </div>
                  </div>

                  <div className="flex flex-row lg:flex-col w-1/4 p-4 flex-wrap">
                    <div className="mt-2">
                      <InfoChipsTechnologyFe
                        title="Frontend"
                        value={dataById?.data.attributes.frontend}
                      ></InfoChipsTechnologyFe>
                    </div>
                    <div className="mt-2">
                      <InfoChipsTechnologyBe
                        title="Backend"
                        value={dataById?.data.attributes.backend}
                      ></InfoChipsTechnologyBe>
                    </div>
                    <div className="mt-2">
                      <InfoChipsTechnologyTest
                        title="Test"
                        value={dataById?.data.attributes.test}
                      ></InfoChipsTechnologyTest>
                    </div>
                    <div className="mt-2">
                      <InfoChipsTechnologyUiUx
                        title="UI/UX"
                        value={dataById?.data.attributes.design}
                      ></InfoChipsTechnologyUiUx>
                    </div>
                  </div>
                </div>

                {dataById?.data.attributes.photos?.data && (
                  <div className="bg-white w-[90%] p-1 shadow-xl shadow-black/10 rounded-b-sm mb-20">
                    <div className="w-12/12 bg-[#f7f7f7]">
                      <GridImages
                        photos={dataById?.data.attributes.photos?.data}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            {/* <div className="w-full max-w-9xl mx-auto">
              <Outlet />
            </div> */}
            {dataById?.data.attributes.photos?.data && (
              <div className="fixed left-[65px] right-0 bottom-0 mt-20">
                <div className="flex justify-end items-center py-3 w-full border-t bg-white pr-4 ">
                  <button
                    onClick={() =>
                      downloadImage(dataById?.data.attributes.photos?.data)
                    }
                    className="bg-primary-button text-white w-36 py-2 px-4 rounded-lg text-base font-bold"
                  >
                    Download
                  </button>
                </div>
              </div>
            )}
          </main>
        </div>
      </div>
    </>
  );
}
