import { Navigate, useRoutes } from 'react-router-dom';
import ArchiveDetail from '../components/main/ArchiveDetail';
import Dashboard from '../pages/dashboard/Dashboard';
import LoginPage from '../pages/users/LoginPage';

export default function RoutesList() {
    return useRoutes([
        {
            path: '/',
            element: <LoginPage />
        },
        {
            path: '/dashboard',
            element: <Dashboard />,
            children: [],
        },
        {
            path: '/info',
            element: <ArchiveDetail />,
            children: [],
        },
        { path: '*', element: <Navigate to='/' replace /> }
    ])
}