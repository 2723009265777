import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setOpenBoxUserView } from "../../store/feature/SetOpenBoxUserSlice";

export default function RowNavigate({row}:any) {
  const navigate = useNavigate();
  const dispatch= useDispatch()

  const handleRowClick = (row: any) => {
    // setId(row.id);
    dispatch(
      setOpenBoxUserView({
        type: "view",
        isOpenView: true,
        infoBox: row?.original,
        tabReference: "consumer",
      })
    );
    navigate("/info");
  };
  return (
    <button
    className="absolute z-5 right-0 left-0 top-0 bottom-0 opacity-0"
    onClick={() => handleRowClick(row)}
  >
  </button>
  )
}
