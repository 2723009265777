import { LogoutIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DialogComponent from "../main/DialogComponent";

export default function DialogLogout() {
  const [isOpen, setOpen] = useState(false);

  const contentDialog = () => (
    <div className="flex flex-col text-center">
      <p> Conferma Logout </p>
    </div>
  );

  const navigate = useNavigate();
  const handleClick = (navigateTo: any) => {
    navigateTo && navigate(navigateTo);
  };

  const DialogInfo = {
    icon: <LogoutIcon className="w-5 h-5 mx-3" />,
    label: "Logout",
    heading: "Logout",
    content: contentDialog(),
    closeButtonText: "Cancella",
    confirmButtonText: "Logout",
    closeButtonAction: () => setOpen(false),
    confirmButtonAction: () => handleClick("Logout"),
    isOpen: isOpen,
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setOpen(true)}
        className="select-none w-full z-40 flex items-center h-14  text-base text-secondary-text hover:bg-skin-bgInput"
      >
        {DialogInfo?.icon} {DialogInfo?.label}
      </button>
      <DialogComponent content={DialogInfo?.content} dialogInfo={DialogInfo} />
    </>
  );
}
