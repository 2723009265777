export const FilterCode = [
  {
    label: "search",
    name: "filter",
    placeholder: "Codice progetto",
    type: "text",
    validators: {},
    type_input: "debounce-input",
    // size_input : 'lg:w-3/5 md:w-1/2 my-4 w-full'
  },
];

export const FilterData = [
  {
    label: "search",
    name: "filter",
    placeholder: "Data",
    type: "text",
    validators: {},
    type_input: "debounce-input",
    // size_input : 'lg:w-3/5 md:w-1/2 my-4 w-full'
  },
];

export const FilterUsers = [
  {
    label: "search",
    name: "filter",
    placeholder: "Progetto",
    type: "text",
    validators: {},
    type_input: "debounce-input",
    // size_input : 'lg:w-3/5 md:w-1/2 my-4 w-full'
  },
];

export const FilterProjects = [
  {
    label: "search",
    name: "filter",
    placeholder: "Progetto",
    type: "text",
    validators: {},
    type_input: "debounce-input",
    // size_input : 'lg:w-3/5 md:w-1/2 my-4 w-full'
  },
];

export const FilterCustomer = [
  {
    label: "search",
    name: "filter",
    placeholder: "Cliente",
    type: "text",
    validators: {},
    type_input: "debounce-input",
    // size_input : 'lg:w-3/5 md:w-1/2 my-4 w-full'
  },
];

export const FilterFinalCustomer = [
  {
    label: "search",
    name: "filter",
    placeholder: "Committente",
    type: "text",
    validators: {},
    type_input: "debounce-input",
    // size_input : 'lg:w-3/5 md:w-1/2 my-4 w-full'
  },
];

export const FilterMarket = [
  {
    label: "search",
    name: "filter",
    placeholder: "Ricerca",
    type: "text",
    validators: {},
    type_input: "debounce-input",
    // size_input : 'lg:w-3/5 md:w-1/2 my-4 w-full'
  },
];

export const FilterTypology = [
  {
    label: "search",
    name: "filter",
    placeholder: "Ricerca",
    type: "text",
    validators: {},
    type_input: "debounce-input",
    // size_input : 'lg:w-3/5 md:w-1/2 my-4 w-full'
  },
];

export const FilterDropDown = [
  {
    label: "search",
    name: "filter",
    placeholder: "Ricerca",
    type: "text",
    validators: {},
    type_input: "debounce-input",
    // size_input : 'lg:w-3/5 md:w-1/2 my-4 w-full'
  },
];
