import { StylesConfig } from "react-select";

export const customStyle = (isFocused: any): StylesConfig => {
    return {
        control: (base: any, { isDisabled}) => ({
            ...base,
            border: "0 !important",
            boxShadow: "0 !important",
            "&:hover": { border: "0 !important" },
            backgroundColor: isDisabled && 'transparent'
        }),
        dropdownIndicator: (base: any) => ({
            ...base,
            color: '#91919c',
            "&:hover": {
                color: '#09acf8'
            },
            "&:focus": {
                color: '#09acf8'
            }
        }),
        singleValue: (base: any) => ({
            ...base,
            color: '#91919c'
        }),
        option: (base: any, { isSelected }) => ({
            ...base,
            color: isSelected && '#91919c',
            background: isSelected ? '#F9F9F9' : '',
            "&:hover": {
                color: '#91919c',
                background: '#F9F9F9'
            }
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible",
            padding: 0,
            margin: 0,
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: (state.hasValue || state.selectProps.inputValue || isFocused) ? -22 : "15%",
            transitionProperty: 'all',
            transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
            transitionDuration: '150ms',
            fontSize: (state.hasValue || state.selectProps.inputValue || isFocused) ? 14 : 16,
            color: (state.selectProps.inputValue || isFocused) ? 'var(--color-text-focus)' : '#9CA3AF',
        }),
    };
}