import { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import DialogImage from "../dialogs/DialogImage";

export default function GridImages(props: { photos: any }) {
  const link = "https://apps2portfolio-api.mobilesoftapps.it/";
  let [isOpen, setIsOpen] = useState(false);
  let [url, setUrl] = useState("");
  const openDialog = (url: string) => {
    setIsOpen(true);
    setUrl(url);
  };
  const onClose = () => {
    setIsOpen(false);
    setUrl("");
  };
  return (
    <div className="flex flex-row justify-center items-center">
      {props.photos &&
        props.photos.map((el: any) => (
          <div key={el.id} className="">
            <div
              className="p-4 "
              onClick={() => openDialog(link + el.attributes.url)}
            >
              <div className="border-2 p-2">
                <img
                  className="max-h-[400px] w-auto"
                  alt=""
                  src={link + el.attributes.formats?.small?.url}
                />
              </div>
            </div>
          </div>
        ))}

      {isOpen && (
        <DialogImage
          open={isOpen}
          url={url}
          onSelect={() => onClose()}
        ></DialogImage>
      )}
    </div>
  );
}
