export default function InfoDetailTextArea(props: {
  title: string;
  value: any;
  type?: string;
}) {
  return (
    <div>
      <h3 className="text-[#8CA0B3] text-[16px] text-left">{props.title}</h3>
      <p className="text-[14px] text-[#B4B7C1] font-light  h-[150px] overflow-auto">
        {props.value ? props.value : "---"}
      </p>
    </div>
  );
}
