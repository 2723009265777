import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import InputsFields from "../../components/helpers/InputFields";
import { SelectDialogType } from "../../helpers/SelectDialogType";
import { useLoginMutation } from "../../services/AppServices";
import {
  RememberMeInfo,
  setRememberMe,
} from "../../store/feature/RememberMeSlice";

import { FormLoginInputs } from "../../utils/inputList/LoginInputs";
import SmallLoader from "./SmallLoader";

export default function LoginForm() {
  const Rememberme = useSelector(RememberMeInfo);
  const [isErrorLogin, setIsErrorLogin] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("errorLoginText");
  const dispatch = useDispatch();

  const form = useForm();
  const navigate = useNavigate();
  const { setFocus, reset, handleSubmit } = form;
  const [login, resultLogin] = useLoginMutation();

  const handleSubmitLoginForm = async (data: any) => {
    const { rememberme, ...updatedData } = data;
    try {
      let result = await login(updatedData).unwrap();
      data.rememberme
        ? dispatch(setRememberMe({ rememberme: true, email: data.email }))
        : dispatch(setRememberMe({ rememberme: false, email: null }));
      setIsErrorLogin(false);

      localStorage.setItem("token", result.jwt);
      navigate("/dashboard");
    } catch (rejectedValueOrSerializedError: any) {
      if (rejectedValueOrSerializedError.error === "USER_NOT_FOUND") {
        setErrorMessage("Utente non trovato");
      } else {
        setErrorMessage("Credenziali Errate");
      }
      setIsErrorLogin(true);
    }

    //    let result = await login(data)
    //    if ('error' in result) {
    //     setErrorMessage('Credenziali errate')
    //     setIsErrorLogin(true)

    // } else {

    //     localStorage.setItem('token', result.data.jwt);
    //     navigate('/dashboard')
    // }
  };
  useEffect(() => {
    setFocus("email");
    reset(Rememberme);
    setFocus("email");
  }, [Rememberme, reset, setFocus]);

  return (
    <form
      className="mt-12 sm:w-3/4 w-full mx-auto space-y-4"
      onSubmit={handleSubmit(handleSubmitLoginForm)}
    >
      {resultLogin.isLoading && (
        <SmallLoader
          hideBg={false}
          isAbsolute={true}
          isBtnLoader={false}
          top={false}
        />
      )}

      <InputsFields
        arrFields={FormLoginInputs}
        form={form}
        typeView={"login"}
      />

      <div className="flex justify-between items-center pt-12">
        {/* <DialogResetPassword /> */}
        {/* <RememberMeBtn form={form} /> */}
      </div>

      <div className="flex items-center justify-center pt-5">
        <button className="select-none bg-primary-button text-white sm:w-1/2 w-64 py-2 px-4 rounded-lg text-base font-bold">
          Login
        </button>
      </div>
      {isErrorLogin &&
        SelectDialogType(
          "DIALOG_ERROR",
          errorMessage,
          "",
          isErrorLogin,
          "Autenticazione fallita",
          setIsErrorLogin
        )}
    </form>
  );
}
