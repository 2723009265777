import { Children, useState } from "react"
import { Controller } from "react-hook-form";
import { Trans } from "react-i18next";
import Select, { components } from 'react-select';
import { customStyle } from "../../components/helpers/stylesHelper";
import ErrorMessageInput from "./ErrorMessageInput";

export default function ReactSelectInput({ input, form, options, type, method }: any) {

    const [valueSel, setValueSel] = useState<null | { value: string, label: string }>(null);
    const { control, setValue, formState: { errors } } = form;
    const [isFocused, setIsFocus] = useState(false);
    
    const handleChange = (valueSelected: any) => {
        setValueSel(valueSelected);
        valueSelected ? setValue(input.name, valueSelected.value, { shouldValidate: true }) : setValue(input.name, null, { shouldValidate: false })

        if (method) {
            method();
        }
    }

    const { ValueContainer, Placeholder } = components;

    const CustomValueContainer = ({ children, ...props }: any) => {
        return (
            <ValueContainer {...props} className={`text-md bg-transparent transition-all`}>
                <Placeholder {...props} isFocused={props.isFocused}>
                    {props.selectProps.placeholder}
                </Placeholder>
                {Children.map(children, child =>
                    child && child.type !== Placeholder ? child : null
                )}
            </ValueContainer>
        );
    };

    const disable = () => {
        return { clear: !input.disable_clear, search: !input.disable_search }
    }

    return (
        <div className={`${input.size_input} h-16 transition-all`}>
            <div className={`h-full flex flex-col justify-end`}>
                <Controller
                    control={control}
                    name={input.name}
                    rules={input.validators}
                    render={({ field }: any) => (
                        <Select
                            onFocus={() => setIsFocus(true)}
                            onBlur={() => setIsFocus(false)}
                            className={`${type === 'view' && 'cursor-not-allowed'} block basic-single ${type === 'view' ? 'border-dotted' : 'border-solid'} border-b-2 transition-all ${isFocused ? 'border-skin-inputFocus' : 'border-gray-300'}`}
                            components={{ ValueContainer: CustomValueContainer }}
                            placeholder={<Trans i18nKey={input.placeholder} />}
                            styles={{ ...customStyle(isFocused), menu: provided => ({ ...provided, zIndex: 9999 }) }}
                            menuPlacement="auto"
                            value={valueSel ? valueSel : field.value}
                            onChange={(e) => (handleChange(e))}
                            options={input.options ? input.options : options}
                            isClearable={disable().clear}
                            isSearchable={disable().search}
                            isDisabled={type === 'view' ? true : false}
                        />
                    )}
                />
            </div>
            <ErrorMessageInput errors={errors} name={input.name} />
        </div>
    );
}