import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/solid";
import { Fragment, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  useExpanded,
  useFilters,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { ReactComponent as ArrowLeftSvg } from "../../assets/mainIcons/arrow_left.svg";
import { ReactComponent as ArrowRightSvg } from "../../assets/mainIcons/arrow_right.svg";
import { ReactComponent as SortSvg } from "../../assets/mainIcons/sort.svg";
import { ReactComponent as LastPageSvg } from "../../assets/mainIcons/last-page.svg";
import { ReactComponent as FirstPageSvg } from "../../assets/mainIcons/first-page.svg";
import InputFilterTable from "./InputFilterTable";
import DropDownType from "./DropDownType";
import { ArchiveModel } from "../../models/archive-model";

export default function TableComponent({
  tabHeader,
  dataTable,
  meta,
  pageIndex,
  pageSizeParent,
  parentMethod,
  sortFun,
  sortDirectionParent,
  currentSortCol,
  messageResult,
  returnFilter,
}: { dataTable: ArchiveModel } & any) {
  const data = dataTable;

  const columns = tabHeader;
  const [pageSize, setPageSize] = useState(
    pageSizeParent ? parseFloat(pageSizeParent) : 5
  );

  const skip: number = (meta?.page - 1) * pageSize;
  const totPage = meta?.total;
  const itemsForPage: number = skip + pageSize;

  const [currentIdRow] = useState(null);
  const { t } = useTranslation();

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      { data, columns, manualSortBy: true, sortFun },
      useFilters,
      useSortBy,
      useExpanded,
      usePagination
    );

  const handleChangePage = (page: any) => {
    parentMethod({ pageIndex: page, pageSize: pageSize });
  };

  const handleSort = (column: any) => {
    let direction: string | null = null;
    if (column.customSort) {
      if (column.id !== currentSortCol) {
        direction = "asc";
      } else if (sortDirectionParent === "asc") {
        direction = "desc";
      } else if (sortDirectionParent === "desc") {
        direction = null;
      } else {
        direction = "asc";
      }

      sortFun(direction, column);
    }
  };

  const handleFilter = (filter: any) => {
    returnFilter(filter);
    // setValuesFil(filter);
    // setInfoQueryPage(infoQueryPage);
    // setPageIndex(0)
  };

  return (
    <div className="flex flex-row w-full ">
      <div className="h-[76px] w-20 bg-white border-b-2 lg:flex hidden"></div>

      <div className="flex flex-col bg-white w-full overflow-auto">
        <div className=" rounded-lg">
          <div className="align-middle inline-block min-w-full min-h-boxHeight rounded-lg">
            <div className="border-b-[16px]">
              <table {...getTableProps()} className="min-w-full text-sm ">
                <thead className="relative">
                  {headerGroups.map((headerGroup: any) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column: any) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className={`${
                            column?.noPadding ? "px-0" : "px-6"
                          } py-1 text-left font-bold text-primary-text  tracking-wider h-[75px] border-b-2  ${
                            column.centerHeader && "text-center"
                          } ${
                            column.id === currentSortCol
                              ? "bg-skin-bgCurrentSortCol"
                              : ""
                          }`}
                        >
                          <div></div>

                          <div
                            className={`my-2 inline-flex group ${
                              column.customSort ? "cursor-pointer" : ""
                            }`}
                          >
                            <span>
                              {column.isSelect && !column.inputSort && (
                                <DropDownType
                                  column={column}
                                  methodParentFilter={handleFilter}
                                ></DropDownType>
                              )}
                              {!column.isSelect && column.inputSort && (
                                <InputFilterTable
                                  title={column.Header}
                                  column={column.columnForSearch}
                                  methodParentFilter={handleFilter}
                                  FilterType={column.filterType}
                                />
                              )}
                              {!column.inputSort &&
                                !column.isSelect &&
                                (typeof column.render("Header") === "string"
                                  ? t(column.render("Header"))
                                  : column.render("Header"))}
                            </span>

                            {column?.customSort && (
                              <span onClick={() => handleSort(column)}>
                                <SortSvg className="w-4 h-4 ml-4 mt-[14px] group-hover:opacity-100 opacity-100 stroke-gray-300" />
                              </span>
                            )}
                            {column.customSort &&
                              currentSortCol === column.id && (
                                <span className="relative top-0">
                                  {sortDirectionParent === "asc" ? (
                                    <ArrowUpIcon className="w-3 h-3 mt-4 ml-[-50px] inline-block" />
                                  ) : sortDirectionParent === "desc" ? (
                                    <ArrowDownIcon className="w-3 h-3 mt-4 ml-[-50px] inline-block" />
                                  ) : null}
                                </span>
                              )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody
                  {...getTableBodyProps()}
                  className="text-skin-cellTab shadow-xl shadow-black/10 w-full"
                >
                  {messageResult && (
                    <tr>
                      <td colSpan={8} className="text-center h-96">
                        {<Trans i18nKey={messageResult} />}
                      </td>
                    </tr>
                  )}
                  {rows.map((row: any) => {
                    prepareRow(row);
                    return (
                      <Fragment key={row.getRowProps().key}>
                        <tr
                          className={`odd:bg-skin-bgOddRow hover:bg-[#F6F6F6] cursor-pointer relative ${
                            currentIdRow === row.id &&
                            "border border-skin-inputFocus border-[#09ACF8]"
                          }`}
                          {...row.getRowProps()}
                        >
                          {row.cells.map((cell: any) => {
                            return (
                              <td
                                {...cell.getCellProps({
                                  style: {
                                    minWidth: cell.column.minWidth,
                                    width: cell.column.width,
                                    maxWidth: cell.column.maxwidth,
                                  },
                                })}
                                className={` ${
                                  cell.column?.noPadding ? "px-0 w-0" : "px-6"
                                } py-4 ${
                                  cell.column?.overflow ? "overflow-hidden" : ""
                                }  whitespace-nowrap ${
                                  cell.column.id === currentSortCol
                                    ? "bg-skin-bgCurrentSortCol"
                                    : ""
                                }`}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      </Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {/* Pagination */}
            <div className="py-8 flex px-8 items-center space-x-4 fill-iconColor  shadow-xl shadow-black/10 bg-white -mt-4">
              <nav className="space-x-4">
                <span className="text-sm text-skin-default">
                  <Trans i18nKey={"lines-per-page"} />
                </span>
                <select
                  aria-label="Seleziona elementi per pagina"
                  className="text-skin-dafault bg-transparent text-skin-default border-0 border-b-2 border-skin-borderInput focus-within:border-b-skin-borderBtn outline-none leading-8 transition-colors duration-200 ease-in-out"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                    parentMethod({
                      pageIndex: 0,
                      pageSize: Number(e.target.value),
                    });
                  }}
                >
                  {[5, 10, 25, 50].map((pageSize) => (
                    <option
                      key={pageSize}
                      value={pageSize}
                      className="bg-skin-bgBoxCol"
                    >
                      {pageSize}
                    </option>
                  ))}
                </select>
              </nav>

              <nav className="inline-flex items-center justify-around space-x-2">
                <button
                  aria-label="prima pagina tabella"
                  onClick={() => handleChangePage(0)}
                  disabled={pageIndex === 0}
                >
                  <FirstPageSvg className="w-6 h-6" />
                </button>
                <button
                  onClick={() => handleChangePage(meta.pageCount)}
                  disabled={pageIndex + 1 === totPage}
                >
                  <LastPageSvg
                    aria-label="ultima pagina tabella"
                    className="w-6 h-6"
                  />
                </button>
              </nav>

              <nav
                className="relative inline-flex items-center text-skin-cellTab"
                aria-label="Pagination"
              >
                <button
                  className="relative inline-flex items-center justify-center pr-2"
                  onClick={() => handleChangePage(pageIndex - 1)}
                  disabled={pageIndex === 0}
                >
                  <ArrowLeftSvg
                    aria-label="pagina precedente"
                    className="w-4 h-4"
                  />
                </button>
                <div className="text-sm mx-5">
                  {skip + 1}-{itemsForPage} of {totPage}
                </div>
                <button
                  className="relative inline-flex items-center justify-center pl-2"
                  onClick={() => handleChangePage(pageIndex + 1)}
                  disabled={itemsForPage + 1 > totPage}
                >
                  <ArrowRightSvg
                    aria-label="pagina successiva"
                    className="w-4 h-4"
                  />
                </button>
              </nav>
              <nav></nav>
            </div>
          </div>
        </div>
      </div>
      <div className="h-[76px] w-20 bg-white border-b-2 lg:flex hidden"></div>
    </div>
  );
}
