import DialogComponent from "../main/DialogComponent";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function DialogError({
  message,
  isOpen,
  title,
  parentMethod,
}: any) {
  const [isOpenDialog, setOpen] = useState(isOpen);

  const { t } = useTranslation();

  const contentDialog = () => (
    <div className="flex flex-col text-center h-20">
      <p>{message}</p>
    </div>
  );

  const handleClose = () => {
    setOpen(false);
    parentMethod(false);
  };

  const DialogInfo = {
    icon: "",
    label: "",
    heading: `${t(title)}`,
    content: contentDialog(),
    closeButtonText: "",
    confirmButtonText: "",
    closeButtonAction: () => handleClose(),
    confirmButtonAction: () => null,
    isOpen: isOpenDialog,
  };

  return (
    <DialogComponent
      content={DialogInfo?.content}
      dialogInfo={DialogInfo}
      disabledBtn={true}
    />
  );
}
