import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/outline";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ImageCarousel from "./imageCarousel";

export default function CarouselDialogImage(props: { photos: any }) {
  const link = "https://apps2portfolio-api.mobilesoftapps.it/";

  return (
    <div className=" w-full justify-center items-center flex flex-row">
      <Carousel
        axis="horizontal"
        showStatus={false}
        dynamicHeight={true}
        centerMode={false}
        renderArrowPrev={(clickHandler, hasPrev) => {
          return (
            <div
              className={`${
                hasPrev ? "absolute" : "hidden"
              } top-0 bottom-0 left-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-20`}
              onClick={clickHandler}
            >
              <ArrowLeftIcon className="w-9 h-9 text-black" />
            </div>
          );
        }}
        renderArrowNext={(clickHandler, hasNext) => {
          return (
            <div
              className={`${
                hasNext ? "absolute" : "hidden"
              } top-0 bottom-0 right-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-20`}
              onClick={clickHandler}
            >
              <ArrowRightIcon className="w-9 h-9 text-black" />
            </div>
          );
        }}
      >
        {props.photos &&
          props.photos.map((el: any) => (
            <div
              key={el.id}
              className="w-full justify-center items-center flex flex-row"
            >
              <div className="  ">
                <ImageCarousel url={link + el.attributes.url}></ImageCarousel>
              </div>
            </div>
          ))}
      </Carousel>
    </div>
  );
}
