export default function InfoChipsTypology(props: {
  title: string;
  value: any[];
}) {
  return (
    <div>
      <h3 className="text-[#8CA0B3] text-[16px]">{props.title}</h3>
      <div className="flex flex-row flex-grow flex-wrap ">
        {props.value &&
          props.value.map((el: any) => (
            <p
              key={el.id}
              className="text-[13px] text-[#556677] capitalize bg-[#ece7e7]  text-center  rounded-xl border-[1px] px-2 mr-1 mt-1"
            >
              {el.typology.data?.attributes.name
                ? el.typology.data?.attributes.name
                : "---"}
            </p>
          ))}
      </div>
    </div>
  );
}
