import { Row } from "react-table";
import ChipsList from "../chipsList/chipsList";
import {
  FilterCustomer,
  FilterData,
  FilterFinalCustomer,
  FilterProjects,
  FilterCode,
} from "../inputList/SearchFilter";
import RowNavigate from "../../components/shared/RowNavigate";
import { ArchiveModel } from "../../models/archive-model";
import ImgPlaceHolder from "../../assets/mainIcons/photos.png";
export const HeaderTab = [
  {
    Header: (
      <img
        src={ImgPlaceHolder}
        className="w-12 h-12 absolute top-4 left-3"
        alt="has photos icon"
      />
    ),
    columnForSearch: "img",
    accessor: "img",
    Cell: ({ row }: { row: Row<ArchiveModel> }) => {
      const hasPhotos = !!row?.original?.attributes?.photos?.data;
      return (
        <div className="text-skin-focus truncate cursor-pointer">
          {hasPhotos ? (
            <svg
              className="text-green-500 h-6 w-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="10" cy="10" r="6" />
            </svg>
          ) : (
            <svg
              className="text-red-500 h-6 w-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="10" cy="10" r="6" />
            </svg>
          )}
        </div>
      );
    },
  },
  {
    Header: "Codice progetto",
    columnForSearch: "code",
    accessor: "code",
    //maxWidth: 50,
    Cell: ({ row }: { row: Row<any> }) => (
      <div className="`text-skin-focus truncate cursor-pointer`">
        {row?.original?.attributes?.code}
      </div>
    ),
    disableSortBy: true,
    customSort: true,
    inputSort: true,
    filterType: FilterCode,
  },
  {
    Header: "Data",
    columnForSearch: "startAt",
    accessor: "startAt",
    //maxWidth: 50,
    Cell: ({ row }: { row: Row<any> }) => (
      <div className="text-skin-focus truncate cursor-pointer">
        {row?.original?.attributes?.startAt}
      </div>
    ),
    disableSortBy: true,
    customSort: true,
    inputSort: true,
    filterType: FilterData,
  },
  {
    Header: "Progetto",
    columnForSearch: "project",
    accessor: "project",
    //maxWidth: 50,
    Cell: ({ row }: { row: Row<any> }) => (
      <>
        {/* className="text-skin-focus truncate cursor-pointer" */}
        {row?.original?.attributes?.project}
      </>
    ),
    disableSortBy: true,
    customSort: true,
    inputSort: true,
    filterType: FilterProjects,
  },
  {
    Header: "Cliente",
    accessor: "finalCustomer",
    columnForSearch: "finalCustomer",
    //maxWidth: 50,
    Cell: ({ row }: { row: Row<any> }) => (
      <div className="text-skin-focus truncate cursor-pointer">
        {row?.original?.attributes.finalCustomer}
      </div>
    ),
    disableSortBy: true,
    customSort: true,
    inputSort: true,
    filterType: FilterFinalCustomer,
  },
  {
    Header: "Committente",
    accessor: "customer",
    columnForSearch: "customer",
    //maxWidth: 50,
    Cell: ({ row }: { row: Row<any> }) => (
      <div className="text-skin-focus truncate cursor-pointer">
        {row?.original.attributes.customer.data?.attributes.name}
      </div>
    ),
    disableSortBy: true,
    customSort: true,
    inputSort: true,
    filterType: FilterCustomer,
  },
  {
    Header: "Mercato",
    accessor: "market",
    // //maxWidth: 50,
    Cell: ({ row }: { row: Row<any> }) => (
      <ChipsList
        list={row?.original?.attributes.market}
        att={"market"}
      ></ChipsList>
    ),
    disableSortBy: true,
    customSort: false,
    isSelect: true,
  },
  {
    Header: "Tipologia",
    accessor: "tipology",
    // //maxWidth: 50,
    Cell: ({ row }: { row: Row<any> }) => (
      <ChipsList
        list={row?.original?.attributes.typology}
        att={"typology"}
      ></ChipsList>
    ),
    disableSortBy: true,
    customSort: false,
    isSelect: true,
  },
  {
    Header: "Frontend",
    accessor: "frontend",
    // //maxWidth: 50,
    Cell: ({ row }: { row: Row<any> }) => (
      <ChipsList
        list={row.original.attributes.frontend}
        att={"technology"}
      ></ChipsList>
    ),
    disableSortBy: true,
    customSort: false,
    isSelect: true,
  },
  {
    Header: "Backend",
    accessor: "backend",
    // //maxWidth: 50,
    Cell: ({ row }: { row: Row<any> }) => (
      // <></>
      <ChipsList
        list={row?.original?.attributes.backend}
        att={"technology"}
      ></ChipsList>
    ),
    disableSortBy: true,
    customSort: false,
    isSelect: true,
  },
  {
    Header: "Test",
    accessor: "test",
    // //maxWidth: 50,
    Cell: ({ row }: { row: Row<any> }) => (
      <ChipsList
        list={row?.original?.attributes.test}
        att={"technology"}
      ></ChipsList>
    ),
    disableSortBy: true,
    customSort: false,
    isSelect: true,
  },
  {
    Header: "UI/UX",
    accessor: "design",
    // maxWidth: 0,
    Cell: ({ row }: { row: Row<any> }) => (
      <ChipsList
        list={row?.original?.attributes.design}
        att={"technology"}
      ></ChipsList>
    ),
    disableSortBy: true,
    customSort: false,
    isSelect: true,
  },
  {
    Header: "",
    accessor: "action",
    width: "0px",
    maxWidth: 0,
    noPadding: true,
    Cell: ({ row }: { row: Row<any> }) => <RowNavigate row={row} />,
    disableSortBy: true,
    customSort: false,
    isSelect: false,
  },
];
